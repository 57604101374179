/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from "aws-amplify";

export const awsConfig: any = {
  aws_appsync_graphqlEndpoint: "https://doznjry2a5astlng452bosbvdm.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  Auth: {
    userPoolId: "eu-west-1_CeJSNbmYU",
    userPoolWebClientId: "nk7kquov3prhfgmqpekshel7q",
    oauth: {
      domain: "myhyd-backend-acc.auth.eu-west-1.amazoncognito.com",
      scope: ["email", "openid", "phone", "profile", "aws.cognito.signin.user.admin"],
      redirectSignIn: "https://dbs.acc.productsupport.dsm.com",
      redirectSignOut: "https://dbs.acc.productsupport.dsm.com",
      responseType: "code"
    }
  },
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken()
      };
    }
  }
};
